import React, {useEffect} from "react";
import Section from "components/Section/Section";
import Hero from "components/Hero/Hero";
import SEO from "components/seo";
import {heroToggle} from "../../store/menuColor";
import {useDispatch} from "react-redux";
import {SectionsWrappper} from "../../shared/Wrappers/wrappers";

const Toolboss = ({data}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    setTimeout(() => dispatch(heroToggle(false)), 500)
  }, [dispatch])
  return (
    <>
      <SEO
        title={data.metaInfo.title}
        description={data.metaInfo.ogDescription}
      />
      <Hero data={data.hero} color="yellow"/>
      <SectionsWrappper>
        <Section data={data.mainCatalogue}/>
      </SectionsWrappper>
    </>
  );
};

export default Toolboss;
