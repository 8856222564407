import { useToolbossPhotos } from "shared/Hooks/toolboss/useToolbossPhotos";

export const useToolbossData = () => {
  const photos = useToolbossPhotos();

  return {
    metaInfo: {
      title: "Toolboss",
      ogDescription:
        "Brak potrzebnego narzędzia w danym czasie i miejscu może prowadzić nawet do zamknięcia całej linii produkcyjnej, co dalej może skutkować wielkimi stratami dla zakładu. Dlatego tak ważna dla działania każdej fabryki jest dystrybucja narzędzi. Automaty ToolBOSS to nie tylko gwarancja dostępu do potrzebnego sprzętu, ale również uporządkowane magazynowanie, skrócony proces wydawania produktu i dokładniejsze monitorowanie stanu i ilości narzędzi.",
      ogImage: "/menu-foto-toolboss.png",
    },
    hero: {
      title: "Automaty ToolBOSS",
      subtitle:
        "Brak potrzebnego narzędzia w danym czasie i miejscu może prowadzić nawet do zamknięcia całej linii produkcyjnej, co dalej może skutkować wielkimi stratami dla zakładu. Dlatego tak ważna dla działania każdej fabryki jest dystrybucja narzędzi. Automaty ToolBOSS to nie tylko gwarancja dostępu do potrzebnego sprzętu, ale również uporządkowane magazynowanie, skrócony proces wydawania produktu i dokładniejsze monitorowanie stanu i ilości narzędzi.",
    },
    mainCatalogue: {
      title: "Opłacalność i wygoda",
      subtitle:
        "Różnych rozmiarów i kształtów automaty ToolBOSS to nie tylko bezpieczne przechowywanie. Podstawowym ich celem jest zwiększenie wydajności i opłacalności. Pozwalają one również na znaczące obniżenie wydatków administracyjnych i zmniejszenie nakładów na nowe narzędzia, a także umożliwiają skrócić proces wydawania produktu. Ponadto zarządzanie nimi jest proste i wygodne, dzięki bezproblemowej integracji z systemami ToolBOSS, CAM i NOVO. ",
      links: [
        {
          url: "/catalogs/toolboss/toolboss.pdf",
          text: "Katalog ToolBoss",
        },
      ],
      bigImg: true,
      src: photos.img1,
    },
  };
};
