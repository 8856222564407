import React from "react";
import { useToolbossData } from "lang/toolboss";
import Toolboss from "sections/Toolboss/Toolboss";

const ToolbossPage = () => {

  const data = useToolbossData();
  return <Toolboss data={data} />;
};

export default ToolbossPage;
