import { graphql, useStaticQuery } from "gatsby";

export const useToolbossPhotos = () => {
  const query = useStaticQuery(graphql`
    query {
      img1: file(relativePath: { eq: "image/toolboss/toolboss-foto.png" }) {
        childImageSharp {
          fluid(maxHeight: 600, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);
  return query;
};
